import * as _defineDataProperty2 from "define-data-property";
var _defineDataProperty = "default" in _defineDataProperty2 ? _defineDataProperty2.default : _defineDataProperty2;
import * as _hasPropertyDescriptors2 from "has-property-descriptors";
var _hasPropertyDescriptors = "default" in _hasPropertyDescriptors2 ? _hasPropertyDescriptors2.default : _hasPropertyDescriptors2;
import * as _functionsHaveNames2 from "functions-have-names";
var _functionsHaveNames = "default" in _functionsHaveNames2 ? _functionsHaveNames2.default : _functionsHaveNames2;
var exports = {};
var define = _defineDataProperty;
var hasDescriptors = _hasPropertyDescriptors();
var functionsHaveConfigurableNames = _functionsHaveNames.functionsHaveConfigurableNames();
var $TypeError = TypeError;
exports = function setFunctionName(fn, name) {
  if (typeof fn !== "function") {
    throw new $TypeError("`fn` is not a function");
  }
  var loose = arguments.length > 2 && !!arguments[2];
  if (!loose || functionsHaveConfigurableNames) {
    if (hasDescriptors) {
      define(fn, "name", name, true, true);
    } else {
      define(fn, "name", name);
    }
  }
  return fn;
};
export default exports;